const isTestServer = location.hostname !== "admin.hellobot.kr" && location.hostname !== "chat.hellobot.kr";

/**
 * Today free
 */
interface TodayFreeItem {
  seq?: number;
  createdAt?: string;
  startDate?: string;
  endDate?: string;
  regDate?: string;
  chatbotSeq?: number;
  chatbotName?: string;
  fixedMenuSeq?: number;
  fixedMenuName?: string;
  bannerSeq?: number;
  bannerTitle?: string;
  bannerImageUrl?: string;
  activated?: boolean;
}

const todayFreeApi = {
  getItems: function(page: number | string, count: number | string) {
    return apiGateway("GET", `/v1/today-free/schedules?pageIndex=${page}&count=${count}`);
  },
  getItem: function(seq: number | string) {
    return apiGateway("GET", `/v1/today-free/schedules/${seq}`)
      .then(data => data.schedule);
  },
  createItem: function(data: TodayFreeItem) {
    return apiGateway("POST", `/v1/today-free/schedules`, data);
  },
  updateItem: function(seq: number | string, data: TodayFreeItem) {
    return apiGateway("PUT", `/v1/today-free/schedules/${seq}`, data);
  },
  deleteItem: function(seq: number | string) {
    return apiGateway("DELETE", `/v1/today-free/schedules/${seq}`);
  },
  refresh: function() {
    return apiGateway("POST", `/v1/today-free/schedules/refresh`);
  },
};


/**
 * Search
 */
interface Tag {
  seq?: number;
  name?: string;
  regDate?: string;
}

interface TagGroup {
  seq?: number;
  name?: string;
  tagNames?: string[];
  fixedMenus?: FixedMenu[];
  fixedMenuSeqs?: number[];
  createdAt?: string;
  matchedCount?: number;
  clickedCount?: number;
}

interface SearchData {
  seq?: number;
  chatbotName?: string;
  fixedMenuName?: string;
  tagGroups?: TagGroup[];
  matchedCount?: {tag: number, skill: number};
  clickedCount?: {tag: number, skill: number};
}

const tagGroupApi = {
  getItems: function(page: number | string, count: number | string, type: string, keyword?: string, startDate?: string, endDate?: string) {
    const params = [`pageIndex=${page}`, `count=${count}`];
    if (keyword) {
      params.push(`keyword=${keyword}`);
    }
    if (startDate) {
      params.push(`startDate=${startDate}`);
    }
    if (endDate) {
      params.push(`endDate=${endDate}`);
    }
    return apiGateway("GET", `/v1/search/statistics/types/${type}?${params.join("&")}`);
  },
  getItem: function(seq: number | string) {
    return apiGateway("GET", `/v1/search/tag-groups/${seq}`)
      .then(data => data.tagGroup);
  },
  createItem: function(data: TagGroup) {
    return apiGateway("POST", `/v1/search/tag-groups`, data);
  },
  updateItem: function(seq: number | string, data: TodayFreeItem) {
    return apiGateway("PUT", `/v1/search/tag-groups/${seq}`, data);
  },
  searchTagName: function(name: string) {
    return apiGateway("GET", `/v1/existsSearchTagByName?name=${name}`)
      .then(data => data.result);
  },
};

/**
 * Chatbot
 */
const chatbotApi = {
  getChatbots: function(status: "database" | "friend" | "all") {
    return apiGateway("POST", "/v1/chatbot/list/" + status, {})
      .then(data => data.chatbots);
  },
  getChatbot: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/chatbot/get", {seq: chatbotSeq});
  },
  createChatbot: function(data: any) {
    return apiGateway("POST", "/v1/chatbot/create", data);
  },
  updateChatbot: function(data: any) {
    return apiGateway("POST", "/v1/chatbot/update", data);
  },
  updateChatbotOrders: function(orders: any) {
    return apiGateway("POST", "/v1/chatbot/update/order", {orders: orders});
  },
  uploadImage: function(file: any) {
    return apiGateway("POST", "/v1/chatbot/upload/image", file, true);
  },
  deleteChatbot: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/chatbot/delete", {seq: chatbotSeq});
  },
  copyChatbot: function(data: any) {
    return apiGateway("POST", "/v1/chatbot/copy", data);
  },
};

/**
 * Cache
 */
const cacheApi = {
  reloadCache: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/cache/reload", {chatbotSeq: chatbotSeq});
  },
  reloadCacheAll: function() {
    return apiGateway("POST", "/v1/cache/reload/all", {});
  },
  deleteCache: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/cache/delete", {chatbotSeq: chatbotSeq});
  },
};

/**
 * Rule
 */
const ruleApi = {
  createRule: function(data: any) {
    return apiGateway("POST", "/v1/rule/create", data);
  },
  getRules: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/rule/load", {chatbotSeq: chatbotSeq});
  },
  getMissRules: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/rule/list/miss", {chatbotSeq: chatbotSeq});
  },
  updateRule: function(data: any) {
    return apiGateway("POST", "/v1/rule/update", data);
  },
  deleteRule: function(seq: number) {
    return apiGateway("POST", "/v1/rule/delete", {seq: seq});
  },
};

/**
 * User
 */
const userApi = {
  getUser: function(userSeq: number) {
    return apiGateway("GET", "/v1/user/" + userSeq)
      .then(data => data.users); // note: 배열로 받음.
  },
  getUserStats: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/user/list/stats", {chatbotSeq: chatbotSeq})
      .then(data => data.users);
  },
  searchUser: function(email: string) {
    return apiGateway("POST", "/v1/user/search/email", {email: email});
  },
  deleteUser: function(seq: number) {
    return apiGateway("POST", "/v1/user/delete", {seq: seq});
  },
};

/**
 * Coin
 */
const coinApi = {
  getCoins: function(userSeq: number) {
    return apiGateway("POST", "/v1/coin/list", {userSeq: userSeq});
  },
  depositCoin: function(data: any) {
    return apiGateway("POST", "/v1/coin/deposit", data);
  },
};

/**
 * Attribute
 */
const attributeApi = {
  getAttributes: function(chatbotSeq: number, userSeq: number) {
    return apiGateway("POST", "/v1/attribute/list", {chatbotSeq: chatbotSeq, userSeq: userSeq})
      .then(data => data.attributes);
  }
};

/**
 * Message
 */
const messageApi = {
  createMessage: function (data: any) {
    return apiGateway("POST", "/v1/message/create", data);
  },
  createLastMessage: function (data: any) {
    return apiGateway("POST", "/v1/message/create/last", data);
  },
  updateMessage: function (data: any) {
    return apiGateway("POST", "/v1/message/update", data);
  },
  updateMessageOrders: function (orders: any) {
    return apiGateway("POST", "/v1/message/update/order", {orders: orders});
  },
  updateLastMessage: function (data: any) {
    return apiGateway("POST", "/v1/message/update/last", data);
  },
  updateMessageImage: function (file: any) {
    return apiGateway("POST", "/v1/message/update/image", file, true);
  },
  updateImageByExcel: function(file: any) {
    return apiGateway("POST", "/v1/chatbot/upload/image", file, true);
  },
  deleteMessage: function (seq: number) {
    return apiGateway("POST", "/v1/message/delete", {seq: seq});
  },
  deleteMessageInBlock: function (seq: number) {
    return apiGateway("POST", "/v1/message/delete/block", {blockSeq: seq});
  },
  deleteLastMessage: function (seq: number) {
    return apiGateway("POST", "/v1/message/delete/last", {blockSeq: seq});
  },
};

/**
 * Block
 */
interface Block {
  seq?: number;
  chatbotSeq?: number;
  name?: string;
  default?: boolean;
  type?: "general" | "analysis" | "choice";
  order?: number;
  createAt?: string;
  blockGroupSeq?: number;
  blockGroupName?: string;
  blockGroupOrder?: number;
}

interface Blocks extends Array<Block> {}

interface BlockGroup {
  seq?: number;
  chatbotSeq?: number;
  name?: string;
  type?: "general" | "special" | "saju" | "daily";
  order?: number;
  createAt?: string;
  blocks?: Blocks;
}

interface BlockGroups extends Array<BlockGroup> {}

const blockApi = {
  getBlocks: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/block/list", {chatbotSeq: chatbotSeq})
      .then(data => data.blocks as Blocks);
  },
  getBlocks2: function(chatbotSeq: number) { // todo 위와 뭐가 다른지 모르겠음
    return apiGateway("POST", "/v1/block/load", {chatbotSeq: chatbotSeq})
      .then(data => data.blocks as Blocks);
  },
  getBlock: function(seq: number) {
    return apiGateway("POST", "/v1/block/get", {seq: seq});
  },
  createBlock: function(data: Block) {
    return apiGateway("POST", "/v1/block/create", data);
  },
  createBlocksByExcel: function(file: any) {
    return apiGateway("POST", "/v1/block/create/blocks/excel", file, true);
  },
  copyBlock: function(seq: number) {
    return apiGateway("POST", "/v1/block/duplicate", {seq: seq});
  },
  updateBlock: function(data: Block) {
    return apiGateway("POST", "/v1/block/update", data);
  },
  updateBlockOrders: function(orders: any) {
    return apiGateway("POST", "/v1/block/update/order", {orders: orders});
  },
  deleteBlock: function(seq: number) {
    return apiGateway("POST", "/v1/block/delete", {seq: seq});
  },
  createBlockGroup: function(data: BlockGroup) {
    return apiGateway("POST", "/v1/block-group/create", data);
  },
  getBlockGroups: function(chatbotSeq: number, type: "general" | "special" | "saju" | "daily" | string = "general") {
    return apiGateway("POST", "/v1/block-group/list", {chatbotSeq: chatbotSeq, type: type})
      .then(data => data.blockGroups as BlockGroups);
  },
  getBlocksWithMessages: function(blockGroupSeq: number) {
    return apiGateway("POST", "/v1/block-group/get/blocks/messages", {seq: blockGroupSeq});
  },
  // 블록그룹 목록 얻기 - 블록 포함형
  getBlocksInGroups(chatbotSeq: number, blockGroupType: "general" | "special" | "saju" | "daily" = "general") {
    return blockApi.getBlockGroups(chatbotSeq, blockGroupType)
      .then(blockGroups => {
        return blockApi.getBlocks(chatbotSeq)
          .then(blocks => {
            blockGroups.forEach(blockGroup => {
              blockGroup.blocks = [];
              blocks.forEach(block => {
                if (block.blockGroupSeq === blockGroup.seq) {
                  blockGroup.blocks.push(block);
                }
              });
            });

            return blockGroups;
          });
      });
  },
  updateBlockGroup: function(data: BlockGroup) {
    return apiGateway("POST", "/v1/block-group/update", data);
  },
  updateBlockGroupOrders: function(data: BlockGroups) {
    return apiGateway("POST", "/v1/block-group/update/order", {orders: data});
  },
  updateBlockGroupNext: function(data: any) {
    return apiGateway("POST", "/v1/block-group/update/next", data);
  },
  deleteBlockGroup: function(blockGroupSeq: number) {
    return apiGateway("POST", "/v1/block-group/delete", {seq: blockGroupSeq});
  },
};

/**
 * FixedMenu
 */
interface FixedMenu {
  seq?: number;
  chatbotSeq?: number;
  chatbotName?: number;
  imageUrl?: string;
  name?: string;
  newUntil?: string;
  extraMessage?: string;
  data?: any;
  price?: number;
  visibleStatus?: "visible" | "invisible" | string;
  order?: number;
  createAt?: string;
}

interface FixedMenus extends Array<FixedMenu> {}

const fixedMenuApi = {
  getFixedMenus: function(chatbotSeq: number) {
    return apiGateway("POST", "/v1/fixed-menu/load", {chatbotSeq: chatbotSeq})
      .then(data => data.fixedMenus as FixedMenus);
  },
  getFixedMenu: function(fixedMenuSeq: number) {
    return apiGateway("POST", "/v1/fixed-menu/get", {seq: fixedMenuSeq});
  },
  createFixedMenu: function(data: FixedMenu) {
    return apiGateway("POST", "/v1/fixed-menu/create", data);
  },
  uploadFixedMenuImage: function(file: any) {
    return apiGateway("POST", "/v1/fixed-menu/upload/image", file, true)
      .then(data => data.imageUrl);
  },
  updateFixedMenu: function(data: FixedMenu) {
    return apiGateway("POST", "/v1/fixed-menu/update", data);
  },
  updateFixedMenuOrders: function(data: FixedMenus) {
    return apiGateway("POST", "/v1/fixed-menu/update/order", {orders: data});
  },
  deleteFixedMenu: function(fixedMenuSeq: number) {
    return apiGateway("POST", "/v1/fixed-menu/delete", {seq: fixedMenuSeq});
  },
};

/**
 * Image (라이브러리)
 */
interface Image {
  seq?: number;
  name?: string;
  width?: number;
  height?: number;
  imageUrl?: string;
  order?: number;
  createAt?: string;
}

interface Images extends Array<Image> {}

const imageApi = {
  getImages: function() {
    return apiGateway("POST", "/v1/image/get/all", {})
      .then(data => data.images as Images);
  },
  getImage: function(imageSeq: number) {
    return apiGateway("POST", "/v1/image/get", {seq: imageSeq});
  },
  createImage: function() {
    return apiGateway("POST", "/v1/image/create", { order: 1 } as Image);
  },
  uploadImage: function(file: any) {
    return apiGateway("POST", "/v1/image/update/image", file, true);
  },
  updateImage: function(data: Image) {
    return apiGateway("POST", "/v1/image/update", data);
  },
  updateImageOrders: function(orders: Images) {
    return apiGateway("POST", "/v1/image/update/order", {orders: orders})
      .then(data => {
        toaster.success(`이미지 순서를 수정했습니다.`);
      }, err => {
        toaster.error(`이미지 순서를 수정하지 못했습니다.`);
      });
  },
  deleteImage: function(imageSeq: number) {
    return apiGateway("POST", "/v1/image/delete", {seq: imageSeq})
      .done(data => {
        toaster.success(`이미지를 삭제했습니다.`);
      })
      .fail( err => {
        toaster.error(`이미지를 삭제하지 못했습니다.`);
      });
  },
};

/**
 * Suntalks
 */
interface Suntalk {
  seq?: number;
  regDate?: string;
  title?: string;
  chatbotSeq?: number;
  linkChatbotSeq?: number;
  chatbotName?: string;
  linkBlockSeq?: number;
  blockName?: string;
  startDate?: string;
  endDate?: string;
  status?: 0 | 1;
  subscription?: 0 | 1;
  sunTalkCount?: number;
  reactionCount?: number;
}

interface Suntalks extends Array<Suntalk> {}

const SuntalkApiUrl = "/v1/suntalks/";

const suntalkApi = {
  getSuntalks: function() {
    return apiGateway("GET", SuntalkApiUrl)
      .then(data => data.suntalks as Suntalks);
  },
  getSuntalk: function(suntalkSeq: number) {
    return apiGateway("GET", SuntalkApiUrl + suntalkSeq)
      .then(data => data.suntalk as Suntalk);
  },
  createSuntalk: function(data: Suntalk) {
    return apiGateway("POST", SuntalkApiUrl, data);
  },
  updateSuntalk: function(suntalkSeq: number, data: Suntalk) {
    return apiGateway("PUT", SuntalkApiUrl + suntalkSeq, data);
  },
  deleteSuntalk: function(suntalkSeq: number, chatbotSeq: number) {
    return apiGateway("PUT", SuntalkApiUrl + `withdraws/${suntalkSeq}`, {chatbotSeq: chatbotSeq});
  },
};

/**
 * Banner
 */
interface Banner {
  seq?: number;
  title?: string;
  imageUrl?: string;
  bannerType?: "url" | "menu" | string;
  linkUrl?: string;
  chatbotSeq?: number;
  chatbotName?: string;
  fixedMenuSeq?: number;
  fixedMenuName?: string;
  visibleStatus?: "visible" | "invisible" | string;
  order?: number;
  createAt?: string;
}

interface Banners extends Array<Banner> {}

const bannerApiUrl = "/v1/banners/";

const bannerApi = {
  getBanners: function() {
    return apiGateway("GET", bannerApiUrl)
      .then(data => data.banners as Banners);
  },
  getBanner: function(bannerSeq: number) {
    return apiGateway("GET", bannerApiUrl + bannerSeq)
      .then(data => data.banner as Banner);
  },
  createBanner: function(data: Banner) {
    return apiGateway("POST", bannerApiUrl, data);
  },
  uploadBannerImage: function(file: any) {
    return apiGateway("POST", bannerApiUrl + "image", file, true)
      .then(data => data.imageUrl);
  },
  updateBanner: function(bannerSeq: number, data: Banner) {
    return apiGateway("PUT", bannerApiUrl + bannerSeq, data);
  },
  updateBannerOrders: function(data: Banners) {
    return apiGateway("PUT", bannerApiUrl + "orders", {orders: data});
  },
  deleteBanner: function(bannerSeq: number) {
    return apiGateway("DELETE", bannerApiUrl + bannerSeq);
  },
};

/**
 * Goods
 */
const goodsApi = {
  createGoods: function(data: any) {
    return apiGateway("POST", "/v1/goods/create", data);
  },
  getGoodsList: function() {
    return apiGateway("POST", "/v1/goods/list", {})
      .then(data => data.goods);
  },
  getGoods: function(goodsSeq: number) {
    return apiGateway("POST", "/v1/goods/get", {seq: goodsSeq});
  },
  updateGoods: function(data: any) {
    return apiGateway("POST", "/v1/goods/update", data);
  },
  updateGoodsOrders: function(data: any) {
    return apiGateway("POST", "/v1/goods/update/order", {orders: data});
  },
  uploadGoodsImage: function(file: any) {
    return apiGateway("POST", "/v1/goods/upload/image", file, true);
  },
  deleteGoods: function(goodsSeq: number) {
    return apiGateway("POST", "/v1/goods/delete", {seq: goodsSeq});
  },
};

/**
 * Notice
 */
const noticeApi = {
  createNotice: function(data: any) {
    return apiGateway("POST", "/v1/notice/create", data);
  },
  getNotices: function() {
    return apiGateway("POST", "/v1/notice/list", {});
  },
  updateNotice: function(data: any) {
    return apiGateway("POST", "/v1/notice/update", data);
  },
  deleteNotice: function(noticeSeq: number) {
    return apiGateway("POST", "/v1/notice/delete", {seq: noticeSeq});
  },
};

/**
 * Faq
 */
const faqApi = {
  createFaq: function(data: any) {
    return apiGateway("POST", "/v1/faq/create", data);
  },
  getFaqsTop: function() {
    return apiGateway("POST", "/v1/faq/list/top", {});
  },
  getFaqsByCate: function(category: string) {
    return apiGateway("POST", "/v1/faq/list/category", {category: category});
  },
  updateFaq: function(data: any) {
    return apiGateway("POST", "/v1/faq/update", data);
  },
  updateFaqOrders: function(data: any) {
    return apiGateway("POST", "/v1/faq/update/order", { orders: data });
  },
  deleteFaq: function(seq: number) {
    return apiGateway("POST", "/v1/faq/delete", {seq: seq});
  },
};

/**
 * Event
 */
const eventApi = {
  createCode: function(data: any) {
    return apiGateway("POST", "/v1/event/create/code", data);
  },
  createEvent: function(data: any) {
    return apiGateway("POST", "/v1/event/create", data);
  },
  getEvents: function() {
    return apiGateway("POST", "/v1/event/list", {});
  },
  getEventCodes: function(eventSeq: number) {
    return apiGateway("POST", "/v1/event/list/codes", {eventSeq: eventSeq});
  },
  updateEvent: function(data: any) {
    return apiGateway("POST", "/v1/event/update", data);
  },
  uploadEventCodeExcel: function(file: any) {
    return apiGateway("POST", "/v1/event/create/codes/excel", file, true);
  },
  deleteEvent: function(eventSeq: number) {
    return apiGateway("POST", "/v1/event/delete", {seq: eventSeq});
  },
};

/**
 * Admin
 */
const adminApi = {
  getAdmins: function() {
    return apiGateway("PUT", "/v1/admin/list", {})
      .then(data => data.admins);
  },
  updateAdmin: function(data: any) {
    return apiGateway("PUT", "/v1/admin/update", data);
  },
};

/**
 * Push
 */
const pushApi = {
  sendPush: function(data: any) {
    return apiGateway("PUT", "/v1/push/send", data);
  },
};


/**
 * apiGateway : return RESTful API
 *
 */
function apiGateway(method: string, uri: string, data?: any, isFile?: boolean) {
  return $.ajax({
    method: method,
    url: uri,
    contentType: !isFile ? "application/json; charset=utf-8" : false,
    processData: !isFile,
    dataType: "json",
    data: data ? (!isFile ? JSON.stringify(data) : data) : undefined,
  })
  .done(data => {
    //
  })
  .fail(err => {
    if (err.status === 413) {
      toaster.error(`Status Code: ${err.status}, 용량이 너무 큽니다.`);
    } else if (err.responseText) {
      // toaster.error(`Status Code: ${err.status},  Status Text: ${err.statusText}`);
      // 알림 처리는 각 부분에서 처리해야 함.
      console.error(err.responseText);
    }
  });
}

/* DataTable defaults */
$.extend( $.fn.dataTable.defaults, {
  searching: true,
  ordering:  false,
  dom: `<'row table-search'f><'row'<'col-sm-5'il><'col-sm-7'p>><tr><'row'<'col-sm-5'il><'col-sm-7'p>>`,
  language: {
    info: `총 <b>_TOTAL_</b>개 <span class="line">|</span> <b>_PAGE_</b> / _PAGES_ 페이지`,
    infoEmpty: `총 <b>_TOTAL_</b>개 <span class="line">|</span> <b>_PAGE_</b> / _PAGES_ 페이지`,
    infoFiltered: `(_MAX_개 중 _TOTAL_개)`,
    lengthMenu: `_MENU_ 개씩 보기`,
    searchPlaceholder: `검색`,
    emptyTable: `데이터가 없습니다.`,
    zeroRecords: `데이터가 없습니다.`,
    paginate: {
      next: `다음`,
      previous: `이전`,
    },
  },
});

/**
 * 처리 메세지 알림 인터페이스
 *
 * toastr 라이브러리 사용. 참고: http://codeseven.github.io/toastr/demo.html
 */
const toaster = {
  initialized: false,
  init: function() {
    if (this.initialized) {
      return;
    }

    toastr.options = {
      closeButton: false,
      showDuration: 300,
      hideDuration: 300,
      closeDuration: 300,
      closeOnHover: false,
      preventDuplicates: true,
      timeOut: 2000,
      extendedTimeOut: 1000,
      positionClass: "toast-top-center",
      // progressBar: true,
    };

    this.initialized = true;
  },
  show: function(message: string, type: "success" | "info" | "warning" | "error" = "success") {
    this.init();

    switch (type) {
      case "success": toastr.success(message); break;
      case "info":    toastr.info(message); break;
      case "warning": toastr.warning(message, undefined, {timeOut: 5000, closeButton: true}); break;
      case "error":   toastr.error(message, undefined, {timeOut: 5000, closeButton: true}); break;
    }
  },
  success: function(message: string) {
    this.show(message, "success");
  },
  info: function(message: string) {
    this.show(message, "info");
  },
  warning: function(message: string) {
    this.show(message, "warning");
  },
  error: function(message: string) {
    this.show(message, "error");
  },
};

function getBlockArray(blocks: any) {
  if (!blocks) {
    return [];
  }

  if (typeof blocks === "number") {
    return [blocks];
  } else if (typeof blocks === "object") {
    return blocks.map((value: any) => {
      return parseInt(value, 10);
    });
  } else if (typeof blocks === "string") {
    const array = blocks.split(",");
    return array.map((value: any) => {
      return parseInt(value, 10);
    });
  }
}

/**
 * 이어지는 블록의 상세페이지를 새탭으로 띄우기
 *
 */
function openBlockDetail() {
  const $select = $(this).prev().find("select");
  const type = $select.data("block-type");
  const blockSeq = getBlockArray($select.val())[0];
  const chatbotName = $("#chatbotName").val();
  const chatbotSeq = $("#chatbotSeq").val();
  let blockName: string;
  let url: string = location.origin;

  if (blockSeq) {
    switch (type) {
      case "block": url += "/database/block/detail/"; break;
      case "tarot": url += "/database/special/taro/"; break;
      case "saju":  url += "/database/saju/block/";   break;
      case "daily":  url += "/database/daily/block/";   break;
      default: console.error("블록 타입을 알 수 없습니다.");
    }
    blockName = $select.find(`option[value=${blockSeq}]`).text();
    blockName = encodeURIComponent(blockName);
    url += `${chatbotName}/${chatbotSeq}/${blockName}/${blockSeq}`;
    window.open(url);
  } else {
    alert("블록을 선택해주세요.");
  }
}

/**
 * Change date format.
 *
 * @param {string} date
 * @returns {string} Date string.
 */
// todo 일부 사용 삭제 -> getTimeHtml로 교체
function dateFormat(date: string, format?: string) {
  if (!date) {
    return "";
  }

  const d = new Date(date);

  if (isNaN(d.getFullYear())) {
    return "";
  }

  const year = d.getFullYear();
  const month = (d.getMonth() + 1) >= 10 ? (d.getMonth() + 1) : "0" + (d.getMonth() + 1);
  const day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
  const hours = d.getHours() >= 10 ? d.getHours() : "0" + d.getHours();
  const minutes = d.getMinutes() >= 10 ? d.getMinutes() : "0" + d.getMinutes();
  return format === "date" ? year + "-" + month + "-" + day : year + "-" + month + "-" + day + " " + hours + ":" + minutes;
}

function changeDateFormat() {
  $("time[datetime]").each(function() {
    $(this).text(dateFormat($(this).attr("datetime")));
  });
}

/**
 * Time Html 만들기
 */
function getTimeHtml(datetime: string, format: string = "YYYY-MM-DD HH:mm", timezone?: string) {
  return `<time datetime="${datetime}" title="${datetime}\n${getTimeByTimezone(datetime, undefined, timezone)}">${getTimeByTimezone(datetime, format, timezone)}</time>`;
}

/**
 * 시간 얻기
 */
function getTimeByTimezone(datetime: string, format?: string, timezone?: string) {
  // @ts-ignore
  return timezone ? (<any>moment)(datetime).tz(timezone).format(format) : (<any>moment)(datetime).format(format);
}

function trimAllInput($parent?: any) {
  try {
    const $input = $parent ? $("input:visible", $parent) : $("input:visible");
    $input.each(function () {
      const value = $(this).val() as string;
      const trimValue = $.trim(value);

      $(this).val(trimValue);
    });
  } catch (e) {
    console.error(e);
  }
}


/**
 * Url 파라미터 값 가져오기
 */
function getUrlParameter(sParam: string): any {
  const sPageURL = decodeURIComponent(window.location.search.substring(1));
  const sURLVariables = sPageURL.split("&");
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
}

/**
 * 내용에 따라 자동으로 높이가 변하는 textarea
 * .auto-height-textarea 클래스를 가진다.
 */
function resizeTextareaHeight(event?: any, element?: any) {
  let textarea: any;

  if (event) {
    textarea = this;
  } else if (element) {
    textarea = element;
  } else {
    textarea = document.querySelectorAll("textarea.auto-height-textarea");
  }

  if (textarea.length) {
    Array.prototype.forEach.call(textarea, setHeight);
  } else if (event || element) {
    setHeight(textarea);
  }

  function setHeight(element: any) {
    // reset
    element.style.height = "10px"; // auto 일 경우 높이가 너무 높게 설정됨. 일부러 작은 값 설정함.
    element.style.visibility = "hidden";
    // set
    element.style.height = element.scrollHeight + 2 + "px"; // 스크롤 명확히 없어지도록 보더값 2 추가함.
    element.style.visibility = "visible";
  }
}

/**
 * 요소의 card item를 jQuery 객체로 얻기
 *
 */
function getCard(element: any): any {
  return $(element).closest(".card-item");
}

/**
 * Bootstrap modal 관련 기능
 *
 */

const bsModal = {
  create: function(modalId: string, formId: string, title: string, type: "create" | "edit", modalBodyHtml: string, modalSize?: "modal-lg" | "modal-sm"): JQuery {
    // 새 모달 만들기
    const deleteBtn = type === "edit" ? `<button class="btn btn-danger pull-left btn-modal-delete" type="button">삭제</button>` : ``;
    const html = `
      <div id="${modalId}" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog ${modalSize ? modalSize : ""}">
          <form class="modal-content" id="${formId}" data-type="${type}">
            <header class="modal-header">
              <button class="close" type="button" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">${title} ${type === "create" ? "추가하기" : "수정하기"}</h4>
            </header>
            <div class="modal-body">
              ${modalBodyHtml}
            </div>
            <footer class="modal-footer">
              ${deleteBtn}
              <button class="btn btn-default" data-dismiss="modal" type="button">취소</button>
              <button class="btn btn-primary" type="submit">${type === "create" ? "추가" : "저장"}</button>
            </footer>
          </form>
        </div>
      </div>
    `;

    // append 후 JQuery 객체 리턴하기
    return bsModal.append(html);
  },
  append: function(modalHtml: string): JQuery {
    const $modal: JQuery = $(modalHtml);
    $modal.appendTo(".wide_frame");

    // 모달 닫힌 후 삭제하기 이벤트 바인딩
    $modal.on("hidden.bs.modal", function() {
      toastr.clear(); // 사라지지 않은 토스트도 같이 없애기
      bsModal.remove($modal);
    });

    return $modal;
  },
  remove: function($modal: JQuery): void {
    $modal.remove();
  },
};


/**
 * 기간 상태 정보 얻기
 * 시작일시 ~ 종료일시 정보와 현재 시간을 비교해서 상태 텍스트와 label className 정보를 배열로 반환한다.
 */
function getPeriodStatus(startDate: string, endDate: string): string[] {
  let periodStatus: string[];

  if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
    periodStatus = ["기간에러", "label-danger"];
  } else if (is.past(new Date(endDate))) {
    periodStatus = ["기간후", "label-default"];
  } else if (is.future(new Date(startDate))) {
    periodStatus = ["기간전", "label-info"];
  } else {
    periodStatus = ["기간중", "label-primary"];
  }

  return periodStatus;
}

$(function() {
  changeDateFormat();

  // 이어지는 블록 링크
  $(document).on("click", ".link-to-block", openBlockDetail);

  $(document).on("keyup", "textarea.auto-height-textarea", resizeTextareaHeight);
  if ($("textarea.auto-height-textarea").length) {
    resizeTextareaHeight();
  }

  // loading 레이어 처리
  //
  const $loading = $("#loading");
  $(document)
    .ajaxStart(function () {
      $loading.delay(100).fadeIn(100);
    })
    .ajaxStop(function () {
      $loading.stop(true, true).hide();
    });

  // 페이지 상/하단으로 스크롤하기
  //
  $("#to-top").on("click", function (event: any): void {
    event.preventDefault();
    $("html, body").stop().animate({scrollTop: 0}, 400);
  });
  $("#to-bottom").on("click", function (event: any): void {
    event.preventDefault();
    $("html, body").stop().animate({scrollTop: $(document).height()}, 400);
  });


  // change favicion
  // 테스트서버용 파비콘 노출하기
  if (isTestServer) {
    const $favicons: JQuery = $("link.favicon");
    $favicons.each(function() {
      $(this).attr("href", $(this).attr("href").replace("favicons/", "favicons/test/"));
    });

    // body 에 클래스 붙이기
    document.body.classList.add("test-server");
  }
});